
































































































import debug from 'debug';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import productService from '@/sparkmembers/services/product';
import { unionBy } from 'lodash';
import debounce from 'lodash.debounce';

import MButton from '@/shared/components/MButton.vue';
import InfiniteLoading from 'vue-infinite-loading';

import { PathProduct } from '@/types/path'

const logging = debug('hs:PathsModalAddProducts');

enum Loading {
  DONE,
  LOADING,
  UPDATING,
  SEARCHING,
}

@Component({
  components: {
    MButton,
    InfiniteLoading,
  },
})
export default class PathsModalAddProducts extends Vue {
  @Prop({ required: true }) modalId!: string;
  @Prop({ required: true }) selecteds!: PathProduct[];
  @Prop({ required: true }) isUpdating!: boolean;

  Loading: any = Loading;
  loading: Loading = Loading.LOADING;

  products: PathProduct[] = [];
  selectedProducts: PathProduct[] = [];

  search: string = '';

  page: number = 1;
  perPage: number = 20;
  totalPages: number = 0;
  totalProducts: number = 0;

  created() {
    this.searchProducts = debounce(this.searchProducts, 500);
  }

  async mounted() {
    await this.getProducts();
    this.selectedProducts = this.selecteds;
  }

  @Watch('search')
  async onChangeSearch() {
    this.loading = Loading.SEARCHING;
    this.products = [];
    this.page = 1;
    await this.searchProducts(this.search);
  }

  searchProducts(search: string) {
    return this.getProducts(search);
  }

  setCheckedProduct(product): boolean {
    return this.selectedProducts.some(({ id }) => id === product.id);
  }

  async getProducts(searchByTitle = '') {
    try {
      const query = [
        { key: 'page', value: this.page },
        { key: 'per_page', value: this.perPage },
        { key: 'courses_only', value: true },
        { key: 'sort', value: 'title' },
      ];

      searchByTitle !== '' && query.push({ key: 'title', value: `${searchByTitle}` });

      const { school_products, total_pages, total_count } = await productService.getAll(query);

      this.totalPages = total_pages;
      this.totalProducts = total_count;

      const formatedProducts = this.formatProducts(school_products);
      this.products = unionBy(this.products, formatedProducts, 'id');
    } catch (error) {
      logging('error on get products', error);
    } finally {
      this.loading = Loading.DONE;
    }
  }

  formatProducts(products): PathProduct[] {
    return products.map(product => ({
      id: product.course.id,
      name: product.title,
      kind: this.$t(`sparkmembers.v2-product-list.${product.course.kind}`) ,
    }));
  }

  async infiniteScrollHandler($state: any) {
    if (this.page < this.totalPages) {
      this.page += 1;
      await this.getProducts(this.search);
      $state?.loaded();
    } else {
      $state?.complete();
    }
  }

  addProducts() {
    this.$emit('addProducts', this.selectedProducts);
  }
}
